import React from 'react';
import { Animated } from "react-animated-css";
// Foundation
import { ReactComponent as FoundationPlatformTitle } from '../../static/assets/101_Foundation_PlatformTitle.svg';
import { ReactComponent as FoundationManDesk } from '../../static/assets/102_Foundation_ManDesk.svg';
import { ReactComponent as FoundationGovernance } from '../../static/assets/104_Foundation_Governance.svg';
import { ReactComponent as FoundationEfficiency } from '../../static/assets/105_Foundation_Efficiency.svg';
import { ReactComponent as FoundationFoundationCenter } from '../../static/assets/106_Foundation_FoundationCenter.svg';
import { ReactComponent as FoundationManDashboard } from '../../static/assets/108_Foundation_ManDashboard.svg';
import { ReactComponent as FoundationWaterPlant } from '../../static/assets/109_Foundation_WaterPlant.svg';
import { ReactComponent as FoundationWallMaps } from '../../static/assets/110_Foundation_WallMaps.svg';
import { ReactComponent as FoundationWallGraphs } from '../../static/assets/111_Foundation_WallGraphs.svg';


class FondationPlatform extends React.Component {
  render() {
    const { active } = this.props;
    return (
      <div className="foundation-container icons-inner-container" style={{ opacity: active ? 1 : 0.2 }}>
        <Animated
          className="icon-animation FoundationManDesk"
          animationOutDelay={0}
          animationInDelay={500}
          animateOnMount={true}
          isVisible={active}
        >
          <FoundationManDesk id="FoundationManDesk" className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation FoundationGovernance"
          animationOutDelay={0}
          animationInDelay={1000}
          animateOnMount={true}
          isVisible={active}
        >
          <FoundationGovernance className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation FoundationEfficiency"
          animationOutDelay={0}
          animationInDelay={1500}
          animateOnMount={true}
          isVisible={active}
        >
          <FoundationEfficiency className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation FoundationFoundationCenter"
          animationOutDelay={0}
          animationInDelay={2000}
          animateOnMount={true}
          isVisible={active}
        >
          <FoundationFoundationCenter className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation FoundationManDashboard"
          animationOutDelay={0}
          animationInDelay={2500}
          animateOnMount={true}
          isVisible={active}
        >
          <FoundationManDashboard className="svg-icon" />
        </Animated>
        <FoundationWaterPlant className="icon-animation FoundationWaterPlant"  style={{ opacity: active ? 1 : 0 }} />
        <FoundationWallMaps className="icon-animation FoundationWallMaps"  style={{ opacity: active ? 1 : 0 }} />
        <FoundationWallGraphs className="icon-animation FoundationWallGraphs"  style={{ opacity: active ? 1 : 0 }} />
        <div className="platform-title">
          <FoundationPlatformTitle style={{ height: '100%' }} />
        </div>
      </div>
    );
  }
}

export default FondationPlatform;