import React, { Component } from 'react';
import { connect } from 'react-redux';
import Main from './containers/Main';
import socketConnection from './utils/common/socketConnection';
import { resetErrorModal } from './utils/common/actions';
import { ReactComponent as ErrorIcon } from './static/assets/report-24px.svg';
import './App.css';


class App extends Component {
  componentDidMount() {
    // this.props.socketConnection();
  }

  render() {
    const { error, isLoading } = this.props;
    return (
      <div className="App h-100">
        <div className={`error-modal ${error ? 'visible' : ''}`}>
          <div className="error-icon-container"><ErrorIcon className="error-icon" /></div> {error} <div className="close-button" onClick={this.props.resetErrorModal}>x</div>
        </div>
        <div className={`common-loader ${isLoading ? 'visible' : ''}`}>
          <div className="lds-dual-ring"></div>
        </div>
        <Main />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.common.error,
    isLoading: state.common.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    socketConnection() {
      dispatch(socketConnection());
    },
    resetErrorModal() {
      dispatch(resetErrorModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
