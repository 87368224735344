import cookie from 'react-cookies';
import Config from '../../config';


export default function socketConnection() {
  return function action(dispatch) {
    let ws;
    let wsClose;
    const ExtentionId = cookie.load('ExtentionId');
    const DVSSiteID = cookie.load('DVSSiteID');

    const { websocketUrl } = Config.network;

    function wsOpen() {
      console.log('openConnection');
    }

    function wsError() {}

    function wsMessage(event) {
      console.log(event)
      const data = JSON.parse(event.data);
      if (data['action'] === 'reload') {
        console.log('reload')
      }
    }

    function connect() {
      const queryString = `?ExtentionId=${ExtentionId}&DVSSiteID=${DVSSiteID}`;
      const endpoint = `${websocketUrl}${queryString}`;
      ws = new WebSocket(endpoint);
      ws.onmessage = wsMessage;
      ws.onopen = wsOpen;
      ws.onerror = wsError;
      ws.onclose = wsClose;
    }

    wsClose = function close() {
      console.log('connection closed');
      setTimeout(() => {
        connect();
      }, 3500);
    };

    connect();
  };
}
