import React from 'react';
import { Animated } from "react-animated-css";
// Automation
import { ReactComponent as AutomationPlatformTitle } from '../../static/assets/201_Automation_PlatformTitle.svg';
import { ReactComponent as AutomationManBot } from '../../static/assets/202_Automation_Man_Bot.svg';
import { ReactComponent as AutomationCenterImage } from '../../static/assets/204_Automation_CenterImage.svg';
import { ReactComponent as AutomationObject } from '../../static/assets/205_Automation_Object.svg';
import { ReactComponent as AutomationSocietyofBots } from '../../static/assets/206_Automation_SocietyofBots.svg';
import { ReactComponent as AutomationWallImages } from '../../static/assets/207_Automation_WallImages.svg';


class AutomationPlatform extends React.Component {
  render() {
    const { active } = this.props;
    return (
      <div className="automated-container icons-inner-container" style={{ opacity: active ? 1 : 0.2 }}>
        <Animated
          className="icon-animation AutomationManBot"
          animationOutDelay={0}
          animationInDelay={500}
          animateOnMount={true}
          isVisible={active}
        >
          <AutomationManBot id="AutomationManBot" className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation AutomationCenterImage"
          animationOutDelay={0}
          animationInDelay={1000}
          animateOnMount={true}
          isVisible={active}
        >
          <AutomationCenterImage className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation AutomationObject"
          animationOutDelay={0}
          animationInDelay={1500}
          animateOnMount={true}
          isVisible={active}
        >
          <AutomationObject className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation AutomationSocietyofBots"
          animationOutDelay={0}
          animationInDelay={2000}
          animateOnMount={true}
          isVisible={active}
        >
          <AutomationSocietyofBots className="svg-icon" />
        </Animated>
        <AutomationWallImages className="icon-animation AutomationWallImages"  style={{ opacity: active ? 1 : 0 }} />
        <div className="platform-title">
          <AutomationPlatformTitle style={{ height: '100%' }} />
        </div>
      </div>
    );
  }
}

export default AutomationPlatform;