import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
// import { Router } from 'react-router-dom';
// import history from './history';
import './static/bootstrap.min.css';
import './static/animate.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Redux Store
import store from './store';
import Raven from 'raven-js'


if (process.env.NODE_ENV === 'production') {
  Raven.config(process.env.REACT_APP_SENTRY_URL).install()
}

const MOUNT_NODE = document.getElementById('root')

render(
  (
    <Provider store={store}>
      <App />
    </Provider>
  ), MOUNT_NODE,
);

serviceWorker.unregister()
