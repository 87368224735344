import React from 'react';
import { Animated } from "react-animated-css";
// Insights
import { ReactComponent as InsightsPlatformTitle } from '../../static/assets/301_Insights_PlatformTitle.svg';
import { ReactComponent as InsightsTwoPeopleTable } from '../../static/assets/302_Insights_TwoPeopleTable.svg';
import { ReactComponent as InsightsCenterImage } from '../../static/assets/304_Insights_CenterImage.svg';
import { ReactComponent as InsightsManDashboard } from '../../static/assets/305_Insights_ManDashboard.svg';
import { ReactComponent as InsightsWomanDashboard } from '../../static/assets/306_Insights_WomanDashboard.svg';
import { ReactComponent as InsightsTwoPeopleScreen } from '../../static/assets/307_Insights_TwoPeopleScreen.svg';
import { ReactComponent as InsightsWallImages } from '../../static/assets/308_Insights_WallImages.svg';


class InsightsPlatform extends React.Component {
  render() {
    const { active } = this.props;
    return (
      <div className="insights-container icons-inner-container" style={{ opacity: active ? 1 : 0.2 }}>
        <Animated
          className="icon-animation InsightsTwoPeopleTable"
          animationOutDelay={0}
          animationInDelay={500}
          animateOnMount={true}
          isVisible={active}
        >
          <InsightsTwoPeopleTable id="InsightsTwoPeopleTable" className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation InsightsCenterImage"
          animationOutDelay={0}
          animationInDelay={1000}
          animateOnMount={true}
          isVisible={active}
        >
          <InsightsCenterImage className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation InsightsManDashboard"
          animationOutDelay={0}
          animationInDelay={1500}
          animateOnMount={true}
          isVisible={active}
        >
          <InsightsManDashboard className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation InsightsWomanDashboard"
          animationOutDelay={0}
          animationInDelay={2000}
          animateOnMount={true}
          isVisible={active}
        >
          <InsightsWomanDashboard className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation InsightsTwoPeopleScreen"
          animationOutDelay={0}
          animationInDelay={2500}
          animateOnMount={true}
          isVisible={active}
        >
          <InsightsTwoPeopleScreen className="svg-icon" />
        </Animated>
        <InsightsWallImages className="icon-animation InsightsWallImages" style={{ opacity: active ? 1 : 0 }} />
        <div className="platform-title">
          <InsightsPlatformTitle style={{ height: '100%' }} />
        </div>
      </div>
    );
  }
}

export default InsightsPlatform;