import React from 'react';
import { connect } from 'react-redux';
import { Animated } from "react-animated-css";
import {
  Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer,
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Text,
} from 'recharts';
import { ReactComponent as Globe } from '../../static/assets/NCB_Globe.svg';
import CheckboxSubResult from '../../components/CheckboxSubResult';
import FondationPlatform from '../../components/FondationPlatform';
import AutomationPlatform from '../../components/AutomationPlatform';
import InsightsPlatform from '../../components/InsightsPlatform';
import IntelligentPlatform from '../../components/IntelligentPlatform';
import SynopsImg from '../../static/images/synops.png';
import data from '../../data';
import { getDataSet, getAssetsData } from '../../utils/dataSet/actions';


class Home extends React.Component {
  constructor(props) {
    super(props);
    this.barChartRef = React.createRef();
  }

  state = {
    wrapRotation: -3,
    activeQuartName: 'scenario_1',
    activeQuartValue: 0,
    pointerIsVisible: true,
    filterPopupVisible: true,
    data: data,
  }

  handleQuartClick = (e) => {
    e.stopPropagation();
    const name = e.currentTarget.getAttribute('name');
    if (name === this.state.activeQuartName) {
      return;
    }
    this.setState({ pointerIsVisible: false, filterPopupVisible: false, resultsActivePage: 0 });
    const that = this;
    if (this.pointerTimeout) {
      clearTimeout(this.pointerTimeout);
    }
    this.pointerTimeout = setTimeout(function() {
        that.setState({ pointerIsVisible: true, filterPopupVisible: true });
      }, 1300);
    const value = e.currentTarget.getAttribute('value');
    let rotationDeg = 3;
    switch(value - this.state.activeQuartValue) {
      case 1:
        rotationDeg = -72;
        break;
      case 2:
        rotationDeg = -144;
        break;
      case 3:
        rotationDeg = 144;
        break;
      case 4:
        rotationDeg = 72;
        break;
      case -1:
        rotationDeg = 72;
        break;
      case -2:
        rotationDeg = 144;
        break;
      case -3:
        rotationDeg = -144;
        break;
      case -4:
        rotationDeg = -72;
        break;
      default:
        rotationDeg = 0;
        break;
    };
    this.awaitBeforeSetState({
      wrapRotation: this.state.wrapRotation + rotationDeg,
      activeQuartValue: value,
      activeQuartName: name,
    }, 800);
  }

  awaitBeforeSetState(states, time) {
    if (this.setStateTimeout) {
      clearTimeout(this.setStateTimeout);
    }
    const that = this;
    this.setStateTimeout = setTimeout(function() {
      that.setState(states);
    }, time)
  }

  castStringInSpans(string) {
    const rotateOffset = ((15 - string.length)* 4.6) / 2  + 10.5;
    return (
      string.split("").map((letter, index) => (
        <span className="letter" style={{ transform: `rotate(${4.6 * index + rotateOffset}deg)`, width: '4.6%' }} key={`${index}-${string}`}>
          {letter}
        </span>
      ))
    );
  }

  castHeaderStringInSpans(string) {
    const rotateOffset = ((15 - string.length)* 4) / 2  + 10.5;
    return (
      string.split("").map((letter, index) => (
        <span className="letter" style={{ transform: `rotate(${4 * index + rotateOffset}deg)`, width: '4.6%' }} key={`${index}-${string}`}>
          {letter}
        </span>
      ))
    );
  }

  customTick = (e) => {
    const element = document.getElementById("root");
    const width = element.offsetWidth;
    const fontSize = width * 0.005;
    return (
      <Text
        fill="#fff"
        x={e.x}
        y={e.y + fontSize}
        radius={e.radius}
        width={95}
        textAnchor={e.textAnchor}
      >
        {e.payload.value}
      </Text>
    );
  }

  customBarTick = (e) => {
    return (
      <g>
      <text
        fill="#fff"
        x={e.x}
        y={e.y + 8}
        radius={e.radius}
        className="custom-bar-tick recharts-text recharts-cartesian-axis-tick-value"
        textAnchor={e.textAnchor}>
        <tspan x={e.x} dy="0em">{e.payload.value}: {this.barChartRef.current ? Math.round(this.barChartRef.current.props.data[e.payload.index]['value']) : 0}</tspan>
      </text>
      </g>
    );
  }

  customRadiusTick = (e) => {
    return (
      null
    )
  }

  searchByNameIndex(arr, name) {
    return arr.map(function(e) { return e.name; }).indexOf(name);
  }

  checkboxClick = (name, type) => {
    const { activeQuartName } = this.state;
    let currentData = this.state.data;
    const index = this.searchByNameIndex(currentData[activeQuartName]['assets'][type], name)
    if (currentData[activeQuartName]['assets'][type][index]['checked'] === 1) {
      currentData[activeQuartName]['assets'][type][index]['checked'] = 0;
    } else {
      currentData[activeQuartName]['assets'][type][index]['checked'] = 1;
    }
    this.setState({data: currentData});
  }

  renderSubCheckboxResults(activeQuart, type) {
    if (!data) {
      return null;
    }
    const toRenderQuestions = data[activeQuart]['assets'];
    if (!toRenderQuestions) {
      return null;
    }
    let resultArr = toRenderQuestions[type];
    if (!resultArr) {
      return null;
    }
    return(
      resultArr.map((resultCheckbox, index) => (
        <CheckboxSubResult
          key={`${index}-checkbox-sub-result`}
          resultCheckbox={resultCheckbox}
          className="col"
          type={type}
          checkboxClick={this.checkboxClick}
          isChecked={resultCheckbox['checked']}
          isCheckbox={resultCheckbox['checked'] !== -1}
          classChecked={resultCheckbox['active'] ? 'active' : ''}
        />
      ))
    );
  }

  prepareBarChartData = (chartData) => {
    const preparedData = JSON.parse(JSON.stringify(chartData));
    const activeFondation = data[this.state.activeQuartName]['assets']['foundation'];
    const activeAutomated = data[this.state.activeQuartName]['assets']['automated'];
    const activeInsights = data[this.state.activeQuartName]['assets']['insights'];
    const activeIntelligent = data[this.state.activeQuartName]['assets']['intelligent'];
    // set current loop
    let current = activeFondation;
    let first = 0;
    let third = 0;
    let fourth = 0;
    let fifth = 0;
    for (let i = 0, length = current.length; i < length; i += 1) {
      if (current[i]['checked'] === 1) {
        first -= current[i]['Yr. 1 & 2'];
        third -= current[i]['Yr. 3'];
        fourth -= current[i]['Yr. 4'];
        fifth -= current[i]['Yr. 5'];
      }
    }
    // set current loop
    current = activeAutomated;
    for (let i = 0, length = current.length; i < length; i += 1) {
      if (current[i]['checked'] === 1) {
        first -= current[i]['Yr. 1 & 2'];
        third -= current[i]['Yr. 3'];
        fourth -= current[i]['Yr. 4'];
        fifth -= current[i]['Yr. 5'];
      }
    }
    // set current loop
    current = activeInsights;
    for (let i = 0, length = current.length; i < length; i += 1) {
      if (current[i]['checked'] === 1) {
        first -= current[i]['Yr. 1 & 2'];
        third -= current[i]['Yr. 3'];
        fourth -= current[i]['Yr. 4'];
        fifth -= current[i]['Yr. 5'];
      }
    }
    // set current loop
    current = activeIntelligent;
    for (let i = 0, length = current.length; i < length; i += 1) {
      if (current[i]['checked'] === 1) {
        first -= current[i]['Yr. 1 & 2'];
        third -= current[i]['Yr. 3'];
        fourth -= current[i]['Yr. 4'];
        fifth -= current[i]['Yr. 5'];
      }
    }
    current = preparedData;
    for (let i = 0, length = current.length; i < length; i += 1) {
      if (current[i]['name'] === 'Yr. 1 & 2') {
        current[i]['value'] += first;
      } else if (current[i]['name'] === 'Yr. 3') {
        current[i]['value'] += third + first;
      } else if (current[i]['name'] === 'Yr. 4') {
        current[i]['value'] += fourth + third + first;
      } else if (current[i]['name'] === 'Yr. 5') {
        current[i]['value'] += fifth + fourth + third + first;
      }
    }
    return current;
  }

  setBarMargin = () => {
    const element = document.getElementById("root");
    const width = element.offsetWidth;
    const marginTop = width * 0.03 > 0 ? width * 0.03 : 5;
    return {top: marginTop, right: 5, bottom: 5, left: 0}
  }

  render() {
    const {
      activeQuartName,
      pointerIsVisible,
      filterPopupVisible,
    } = this.state;
    return (
      <div className="h-100 w-100 overflow-hidden results-page">
        <img className="synops-logo" src={SynopsImg} alt="Synops logo" />
        <div className="filter-round-button-container">
          <div className="filter-round-button-inner-container">
            <Globe id="globe" />
            <div className="wrap" style={{transform: `rotate(${this.state.wrapRotation}deg)`, transition: `transform 0.8s ease-in-out`}}>
              <div className={`quart ${activeQuartName === "scenario_4" ? 'active' : ''}`} name="scenario_4" value={3} onClick={this.handleQuartClick} >
                <span className="header-around-circle">
                  { this.castStringInSpans('Scenario 4') }
                </span>
              </div>
              <div className={`quart ${activeQuartName === "scenario_4a" ? 'active' : ''}`} name="scenario_4a" value={4} onClick={this.handleQuartClick} >
                <span className="header-around-circle">
                  { this.castStringInSpans('Scenario 4A') }
                </span>
              </div>
              <div className={`quart ${activeQuartName === "scenario_1" ? 'active' : ''}`} name="scenario_1" value={0} onClick={this.handleQuartClick} >
                <span className="header-around-circle">
                  { this.castStringInSpans('Scenario 1') }
                </span>
              </div>
              <div className={`quart ${activeQuartName === "scenario_3" ? 'active' : ''}`} name="scenario_3" value={2} onClick={this.handleQuartClick} >
                <span className="header-around-circle">
                  { this.castStringInSpans('Scenario 3') }
                </span>
              </div>
              <div className={`quart ${activeQuartName === "scenario_2" ? 'active' : ''}`} name="scenario_2" value={1} onClick={this.handleQuartClick} >
                <span className="header-around-circle">
                  { this.castStringInSpans('Scenario 2') }
                </span>
              </div>
              <div className="center"></div>
            </div>
            <div className="pointer" style={{ opacity: `${pointerIsVisible ? 1 : 0}` }}>
            </div>
          </div>
        </div>
        <Animated
          className="filter-popup"
          isVisible={filterPopupVisible}
          animationInDuration={800}
          animationOutDuration={800}
          animationOutDelay={0}
          animationInDelay={0}
          animateOnMount={false}
        >
          <div className="checkboxes-container">
            <div className="inner-checkboxes-container">
              <h3>{ data[activeQuartName]['name'] }</h3>
            </div>
          </div>
          <div className="type-header foundation-header">
            <h4>Foundation</h4>
          </div>
          <div className="results-checkboxes foundation-results-checkboxes">
            {
              this.renderSubCheckboxResults(activeQuartName, 'foundation')
            }
          </div>
          <div className="type-header automated-header">
            <h4>Automated</h4>
          </div>
          <div className="results-checkboxes automated-results-checkboxes">
            {
              this.renderSubCheckboxResults(activeQuartName, 'automated')
            }
          </div>
          <div className="type-header insights-header">
            <h4>Insights</h4>
          </div>
          <div className="results-checkboxes insights-results-checkboxes">
            {
              this.renderSubCheckboxResults(activeQuartName, 'insights')
            }
          </div>
          <div className="type-header intelligent-header">
            <h4>Intelligent</h4>
          </div>
          <div className="results-checkboxes intelligent-results-checkboxes">
            {
              this.renderSubCheckboxResults(activeQuartName, 'intelligent')
            }
          </div>
          <div className="results-list">
            
            <div className="business-outcomes-text">
              { this.castHeaderStringInSpans(data[activeQuartName]['header']) }
            </div>
          </div>
        </Animated>
        <div className="progression-container">
          <div className="progress-bar-header">
            FTEs
          </div>
          <div className="calendar-container">
            <div className="fte-progress" style={{ height: `${data[activeQuartName]['ftes'].length > 0 ? '50%' : 0}` }}>
            </div>
            <div className="fte-second-progress" style={{ height: `${data[activeQuartName]['ftes'].length > 1 ? '51%' : 0}` }}>
            </div>
          </div>
          <div className="fte-popup fte-first" style={{ opacity: data[activeQuartName]['ftes'].length > 0 ? 1 : 0 }}>
            300 FTE <br />
            Incumbent<br />
            Scope
          </div>
          <div className="fte-popup fte-second" style={{ opacity: data[activeQuartName]['ftes'].length > 1 ? 1 : 0 }}>
            200 FTE <br />
            BU Scope
          </div>
        </div>
        <div className="icons-container" >
          <div className="icons-rows-container">
            <IntelligentPlatform active={data[activeQuartName]['active_platforms'] >= 4} />
            <InsightsPlatform active={data[activeQuartName]['active_platforms'] >= 3} />
            <AutomationPlatform active={data[activeQuartName]['active_platforms'] >= 2} />
            <FondationPlatform active={data[activeQuartName]['active_platforms'] >= 1} />
          </div>

          <div className="month-counter-container" style={{ opacity: 1 }}>
            <div className="progress-bar-header">
              TRANSITION TIME
            </div>
            <div className="calendar-container">
              <div className="month-progress-bar" style={{ height: `${data[activeQuartName]['transition_time'] / 24 * 100}%` }}>
              </div>
            </div>
            <div className="intelligent-date calendar-date" style={{ opacity: 1 }} >
              24 mths
            </div>
            <div className="insights-date calendar-date" style={{ opacity: 1 }} >
              18 mths
            </div>
            <div className="automated-date calendar-date" style={{ opacity: 1 }} >
              12 mths
            </div>
            <div className="foundation-date calendar-date" style={{ opacity: 1 }} >
              6 mths
            </div>
            <div className="month-popup" style={{ bottom: `${data[activeQuartName]['transition_time'] / 24 * 100 - 3}%` }}>
              {data[activeQuartName]['transition_time']} mths
            </div>
          </div>

          <div id="barChart">
            <ResponsiveContainer width='100%'>
              <BarChart
                data={this.prepareBarChartData(data[activeQuartName]['bar_chart'])}
                margin={{top: 10, right: 0, bottom: 10, left: 0}}
                barCategoryGap="25%"
                left={0}
                ref={this.barChartRef}
              >
                <CartesianGrid strokeDasharray="3 0" stroke="rgba(255,255,255,0.3)" />
                <XAxis stroke="#FFF" xAxisId={0} dataKey="name" tick={this.customBarTick} />
                <XAxis xAxisId={1} hide dataKey="name" />
                <YAxis tick={this.customRadiusTick} hide={true} />
                <Bar radius={[15, 15, 0, 0]} xAxisId={0} dataKey="valueMax" fill="#FF5F58" />
                <Bar radius={[15, 15, 0, 0]} xAxisId={1} dataKey="value" fill="rgba(252,228,80,0.9)" />
              </BarChart>
            </ResponsiveContainer>
            <div className="comment-label yellow-label">
              FTEs Units <br/>at Year End
            </div>
            <div className="comment-label red-label">
              Baseline FTE
            </div>
            <div className="main-label">
              GLIDE PATH TOWARD PRODUCTIVITY
            </div>
          </div>
        </div>
         <div className="additional-header additional-top">
          1. Pick a scenario from the turn table
        </div>
        <div className="additional-header additional-bottom">
          2. Select range of tools to model productivity glidepath
        </div>
        <div id="radarChart">
          <ResponsiveContainer width="100%" height="100%">
            <RadarChart data={data[activeQuartName]['radar_chart']} >
              <PolarGrid stroke="rgba(255,255,255,0.3)" />
              <PolarAngleAxis dataKey="name" tick={this.customTick} />
              <PolarRadiusAxis ticks={[2, 3, 4, 5, 6]} orientation="middle" axisLine={false} angle={90} tick={this.customRadiusTick} />
              <Radar name="" dataKey="value" stroke="#FCE450" strokeWidth={2} fill="transparent" />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // dataSet: state.dataSet.dataSet,
    // assetsData: state.dataSet.assetsData,
    // activeOffering: state.dataSet.activeOffering,
    // activeSubOffering: state.dataSet.activeSubOffering,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDataSet(persona) {
      dispatch(getDataSet(persona));
    },
    getAssetsData() {
      dispatch(getAssetsData());
    },
    // getActiveOffering() {
    //   dispatch(getActiveOffering());
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
