const data = {
  'scenario_1': {
    'active_platforms': 2,
    'name': 'Incumbent Scope',
    'header': 'Productivity ~40%',
    'transition_time': 8,
    'ftes': [
      {'name': 'Incumbent Scope', 'value': 300},
    ],
    'bar_chart': [
      {'name': 'Baseline', 'value': 295, valueMax: 295 },
      {'name': 'Yr. 1 & 2', 'value': 295, valueMax: 295 },
      {'name': 'Yr. 3', 'value': 295, valueMax: 295 },
      {'name': 'Yr. 4', 'value': 295, valueMax: 295 },
      {'name': 'Yr. 5', 'value': 295, valueMax: 295 },
    ],
    'radar_chart': [
      {'name': 'Reduce Cost to Serve', value: 3, max: 6},
      {'name': 'Business Outcomes', value: 1, max: 6},
      {'name': 'Dependency on NBCU', value: 3, max: 6},
      {'name': 'Level of Business Change', value: 1, max: 6},
      {'name': 'Customer Experience', value: 2, max: 6},
      {'name': 'Technology Adoption', value: 3, max: 6},
    ],
    'assets': {
      'foundation': [
        {
          'name': 'Operations &\nTransitions suite',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'SynOps',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nWorkforce',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 3.17171414524247,
          'Yr. 3': 5.91240677907659,
          'Yr. 4': 9.70147275153863,
          'Yr. 5': 7.43364678004477,
        },
        {
          'name': 'Collections\nWorkbench',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.70659373778562,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Buyer\nPortal',
          'color': '#FCE450',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Business\nExcellence',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 24.1062733656682,
          'Yr. 3': 7.39050847384574,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'automated': [
        {
          'name': 'RPA',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 29.1635739325003,
          'Yr. 3': 10.346711863384,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'AIDT',
          'color': '#DEABD5',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Blackline',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 2.85329686889281,
          'Yr. 3': 1.47810169476915,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'SAP',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 1.26743979627157,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'insights':[
        {
          'name': 'Process\nAnalytics',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'ATCAT',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Payables\nOptimizer',
          'color': '#FECA7A',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Customer\nSegmentation Lite',
          'color': '#FECA7A',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Analytics',
          'color': '#FECA7A',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 2.53487959254315,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'intelligent': [
        {
          'name': 'Tradeshift',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Cash\nApplication',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.39129111599949,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Credit\n& Collections',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Vendor Contract\nCompliance Advisor',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nCollections',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Period End\nAccelerator',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Advisor',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
    },
  },
  'scenario_2': {
    'active_platforms': 2,
    'name': 'Incumbent Scope + BU Scope',
    'header': 'Productivity ~40%',
    'transition_time': 24,
    'ftes': [
      {'name': 'Incumbent Scope', 'value': 300},
      {'name': 'BU Scope', 'value': 200},
    ],
    'bar_chart': [
      {'name': 'Baseline', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 1 & 2', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 3', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 4', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 5', 'value': 495, valueMax: 495 },
    ],
    'radar_chart': [
      {'name': 'Reduce Cost to Serve', value: 4, max: 6},
      {'name': 'Business Outcomes', value: 2, max: 6},
      {'name': 'Dependency on NBCU', value: 3, max: 6},
      {'name': 'Level of Business Change', value: 3, max: 6},
      {'name': 'Customer Experience', value: 3, max: 6},
      {'name': 'Technology Adoption', value: 3, max: 6},
    ],
    'assets': {
      'foundation': [
        {
          'name': 'Operations &\nTransitions suite',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'SynOps',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nWorkforce',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.05078733031674,
          'Yr. 3': 10.3733333333333,
          'Yr. 4': 28.48,
          'Yr. 5': 15.27,
        },
        {
          'name': 'Collections\nWorkbench',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.50702262443439,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Buyer\nPortal',
          'color': '#FCE450',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Business\nExcellence',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 36.7564162895927,
          'Yr. 3': 22.8213333333333,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'automated': [
        {
          'name': 'RPA',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 25.5397647058823,
          'Yr. 3': 26.9706666666667,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'AIDT',
          'color': '#DEABD5',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Blackline',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.15679638009049,
          'Yr. 3': 2.07466666666667,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'SAP',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'insights':[
        {
          'name': 'Process\nAnalytics',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'ATCAT',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Payables\nOptimizer',
          'color': '#FECA7A',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Customer\nSegmentation Lite',
          'color': '#FECA7A',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 2.41938461538462,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Analytics',
          'color': '#FECA7A',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 2.28117647058823,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'intelligent': [
        {
          'name': 'Tradeshift',
          'color': '#FBBAB8',
          'active': false,
            'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Cash\nApplication',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 8.03241628959275,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Credit\n& Collections',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Vendor Contract\nCompliance Advisor',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nCollections',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Period End\nAccelerator',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Advisor',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
    },
  },
  'scenario_3': {
    'active_platforms': 2,
    'name': 'Scenario 3 + Accelerated BU Transition',
    'header': 'Productivity ~40%',
    'transition_time': 13,
    'ftes': [
      {'name': 'Incumbent Scope', 'value': 300},
      {'name': 'BU Scope', 'value': 200},
    ],
    'bar_chart': [
      {'name': 'Baseline', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 1 & 2', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 3', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 4', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 5', 'value': 495, valueMax: 495 },
    ],
    'radar_chart': [
      {'name': 'Reduce Cost to Serve', value: 4, max: 6},
      {'name': 'Business Outcomes', value: 2, max: 6},
      {'name': 'Dependency on NBCU', value: 3, max: 6},
      {'name': 'Level of Business Change', value: 3, max: 6},
      {'name': 'Customer Experience', value: 3, max: 6},
      {'name': 'Technology Adoption', value: 3, max: 6},
    ],
    'assets': {
      'foundation': [
        {
          'name': 'Operations &\nTransitions suite',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'SynOps',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nWorkforce',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.52258986996965,
          'Yr. 3': 9.62448169591908,
          'Yr. 4': 20.8795676783454,
          'Yr. 5': 13.7623482203118,
        },
        {
          'name': 'Collections\nWorkbench',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 6.61106563070432,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Buyer\nPortal',
          'color': '#FCE450',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Business\nExcellence',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 35.4729128738799,
          'Yr. 3': 21.173859731022,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'automated': [
        {
          'name': 'RPA',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 39.7179322920947,
          'Yr. 3': 25.0236524093896,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'AIDT',
          'color': '#DEABD5',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Blackline',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 7.40736968468124,
          'Yr. 3': 1.92489633918382,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'SAP',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 1.08847576073467,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'insights':[
        {
          'name': 'Process\nAnalytics',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'ATCAT',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Payables\nOptimizer',
          'color': '#FECA7A',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Customer\nSegmentation Lite',
          'color': '#FECA7A',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 0.876515120273245,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Analytics',
          'color': '#FECA7A',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.44237880367333,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'intelligent': [
        {
          'name': 'Tradeshift',
          'color': '#FBBAB8',
          'active': false,
            'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Cash\nApplication',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 9.37236056568915,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Credit\n& Collections',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Vendor Contract\nCompliance Advisor',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nCollections',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Period End\nAccelerator',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Advisor',
          'color': '#FBBAB8',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
    },
  },
  'scenario_4': {
    'active_platforms': 4,
    'name': 'Scenario 4 + Transformational Technology',
    'header': 'Productivity ~50%',
    'transition_time': 13,
    'ftes': [
      {'name': 'Incumbent Scope', 'value': 300},
      {'name': 'BU Scope', 'value': 200},
    ],
    'bar_chart': [
      {'name': 'Baseline', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 1 & 2', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 3', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 4', 'value': 495, valueMax: 495 }, 
      {'name': 'Yr. 5', 'value': 495, valueMax: 495 },
    ],
    'radar_chart': [
      {'name': 'Reduce Cost to Serve', value: 5, max: 6},
      {'name': 'Business Outcomes', value: 4, max: 6},
      {'name': 'Dependency on NBCU', value: 2, max: 6},
      {'name': 'Level of Business Change', value: 4, max: 6},
      {'name': 'Customer Experience', value: 5, max: 6},
      {'name': 'Technology Adoption', value: 5, max: 6},
    ],
    'assets': {
      'foundation': [
        {
          'name': 'Operations &\nTransitions suite',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'SynOps',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nWorkforce',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.25734335839599,
          'Yr. 3': 8.42093023255815,
          'Yr. 4': 12.672,
          'Yr. 5': 9.14526315789475,
        },
        {
          'name': 'Collections\nWorkbench',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 2.82666666666667,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Buyer\nPortal',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Business\nExcellence',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 29.4142857142857,
          'Yr. 3': 18.5260465116279,
          'Yr. 4': 5.63199999999999,
          'Yr. 5': 0,
        },
      ],
      'automated': [
        {
          'name': 'RPA',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 26.9041604010025,
          'Yr. 3': 18.5260465116279,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'AIDT',
          'color': '#DEABD5',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Blackline',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 7.58511278195488,
          'Yr. 3': 3.36837209302326,
          'Yr. 4': 4.22399999999999,
          'Yr. 5': 0,
        },
        {
          'name': 'SAP',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 0.910526315789473,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'insights':[
        {
          'name': 'Process\nAnalytics',
          'color': '#FECA7A',
          'active': true,
            'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'ATCAT',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Payables\nOptimizer',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Customer\nSegmentation Lite',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Analytics',
          'color': '#FECA7A',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 3.64210526315789,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'intelligent': [
        {
          'name': 'Tradeshift',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 13.6578947368421,
          'Yr. 3': 16.8418604651163,
          'Yr. 4': 12.672,
          'Yr. 5': 12.5747368421053,
        },
        {
          'name': 'IRCM Cash\nApplication',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 8.79654135338345,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Credit\n& Collections',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 10.9263157894737,
          'Yr. 3': 6.73674418604652,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Vendor Contract\nCompliance Advisor',
          'color': '#FBBAB8',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nCollections',
          'color': '#FBBAB8',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Period End\nAccelerator',
          'color': '#FBBAB8',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Advisor',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 2.01904761904762,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
    },
  },
  'scenario_4a': {
    'active_platforms': 4,
    'name': 'Scenario 4A + Transformational Technology',
    'header': 'Productivity ~50%',
    'transition_time': 13,
    'ftes': [
      {'name': 'Incumbent Scope', 'value': 300},
      {'name': 'BU Scope', 'value': 200},
    ],
    'bar_chart': [
      {'name': 'Baseline', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 1 & 2', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 3', 'value': 495, valueMax: 495 },
      {'name': 'Yr. 4', 'value': 495, valueMax: 495 }, 
      {'name': 'Yr. 5', 'value': 495, valueMax: 495 },
    ],
    'radar_chart': [
      {'name': 'Reduce Cost to Serve', value: 5, max: 6},
      {'name': 'Business Outcomes', value: 4, max: 6},
      {'name': 'Dependency on NBCU', value: 2, max: 6},
      {'name': 'Level of Business Change', value: 4, max: 6},
      {'name': 'Customer Experience', value: 5, max: 6},
      {'name': 'Technology Adoption', value: 5, max: 6},
    ],
    'assets': {
      'foundation': [
        {
          'name': 'Operations &\nTransitions suite',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'SynOps',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nWorkforce',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 5.25734335839599,
          'Yr. 3': 8.42093023255815,
          'Yr. 4': 12.672,
          'Yr. 5': 9.14526315789475,
        },
        {
          'name': 'Collections\nWorkbench',
          'color': '#FCE450',
          'active': false,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Buyer\nPortal',
          'color': '#FCE450',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Business\nExcellence',
          'color': '#FCE450',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 29.4142857142857,
          'Yr. 3': 18.5260465116279,
          'Yr. 4': 5.63199999999999,
          'Yr. 5': 0,
        },
      ],
      'automated': [
        {
          'name': 'RPA',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 26.9041604010025,
          'Yr. 3': 18.5260465116279,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'AIDT',
          'color': '#DEABD5',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Blackline',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 7.58511278195488,
          'Yr. 3': 3.36837209302326,
          'Yr. 4': 4.22399999999999,
          'Yr. 5': 0,
        },
        {
          'name': 'SAP',
          'color': '#DEABD5',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 0.910526315789473,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'insights':[
        {
          'name': 'Process\nAnalytics',
          'color': '#FECA7A',
          'active': true,
            'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'ATCAT',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Payables\nOptimizer',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Customer\nSegmentation Lite',
          'color': '#FECA7A',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Analytics',
          'color': '#FECA7A',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 3.64210526315789,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
      'intelligent': [
        {
          'name': 'Tradeshift',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 31,
          'Yr. 3': 14,
          'Yr. 4': 13,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Cash\nApplication',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 8.79654135338345,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'IRCM Credit\n& Collections',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 18,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Vendor Contract\nCompliance Advisor',
          'color': '#FBBAB8',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Intelligent\nCollections',
          'color': '#FBBAB8',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'Period End\nAccelerator',
          'color': '#FBBAB8',
          'active': true,
          'checked': -1,
          'Yr. 1 & 2': 0,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
        {
          'name': 'JE Advisor',
          'color': '#FBBAB8',
          'active': true,
          'checked': 1,
          'Yr. 1 & 2': 2.01904761904762,
          'Yr. 3': 0,
          'Yr. 4': 0,
          'Yr. 5': 0,
        },
      ],
    },
  },
}

export default data