const initialState = {
  error: null,
  isLoading: false,
};


const commonReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'REQUEST_SUCCESSFUL':
    return Object.assign({}, state, {
      error: null,
      isLoading: false,
    });
  case 'REQUEST_FAILURE':
    return Object.assign({}, state, {
      error: action.payload.error,
      isLoading: false,
    });
  case 'МАКЕ_REQUEST':
    return Object.assign({}, state, {
      error: null,
      isLoading: true,
    });
  case 'RESET_ERROR':
    return Object.assign({}, state, {
      error: null,
    });
  default:
    return state;
  }
};


export default commonReducer;
