import React from 'react';
import { ReactComponent as Checkmark } from '../../static/assets/check.svg';


class CheckboxSubResult extends React.Component {
  render() {
    const {
      resultCheckbox, classChecked, oneLine, isChecked, isCheckbox, checkboxClick, type,
    } = this.props;
    return (
      <div
        className={`checkbox-inline ${oneLine ? 'w-100' : ''} ${isCheckbox ? 'is-checkbox' : ''}`}
        onClick={isCheckbox && classChecked ? () => checkboxClick(resultCheckbox['name'], type) : null}
      >
        <div
          className={`checkbox-result ${classChecked} ${isChecked ? 'is-checked' : ''}`}
          style={{ backgroundColor: resultCheckbox['color']}}
        >
          <Checkmark className="fas fa-check-circle" /> {resultCheckbox['name']}
        </div>
      </div>
    );
  }
}

export default CheckboxSubResult;

