import React from 'react';
import { Animated } from "react-animated-css";
// Intelligent
import { ReactComponent as IntelligentPlatformTitle } from '../../static/assets/401_Intelligent_PlatformTitle.svg';
import { ReactComponent as IntelligentManOnDevice } from '../../static/assets/402_Intelligent_ManOnDevice.svg';
import { ReactComponent as IntelligentManBehindTable } from '../../static/assets/403_Intelligent_ManBehindTable.svg';
import { ReactComponent as IntelligentInsightsDrivenProcess } from '../../static/assets/404_Intelligent_InsightsDrivenProcess.svg';
import { ReactComponent as IntelligentCenterImage } from '../../static/assets/405_Intelligent_CenterImage.svg';
import { ReactComponent as IntelligentWomanMap } from '../../static/assets/407_Intelligent_WomanMap.svg';
import { ReactComponent as IntelligentWallWindows } from '../../static/assets/408_Intelligent_WallWindows.svg';


class IntelligentPlatform extends React.Component {
  render() {
    const { active } = this.props;
    return (
      <div className="intelligent-container icons-inner-container" style={{ opacity: active ? 1 : 0.2 }}>
        <Animated
          className="icon-animation IntelligentManOnDevice"
          animationOutDelay={0}
          animationInDelay={500}
          animateOnMount={true}
          isVisible={active}
        >
          <IntelligentManOnDevice id="IntelligentManOnDevice" className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation IntelligentManBehindTable"
          animationOutDelay={0}
          animationInDelay={1000}
          animateOnMount={true}
          isVisible={active}
        >
          <IntelligentManBehindTable className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation IntelligentInsightsDrivenProcess"
          animationOutDelay={0}
          animationInDelay={1500}
          animateOnMount={true}
          isVisible={active}
        >
          <IntelligentInsightsDrivenProcess className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation IntelligentCenterImage"
          animationOutDelay={0}
          animationInDelay={2000}
          animateOnMount={true}
          isVisible={active}
        >
          <IntelligentCenterImage className="svg-icon" />
        </Animated>
        <Animated
          className="icon-animation IntelligentWomanMap"
          animationOutDelay={0}
          animationInDelay={2500}
          animateOnMount={true}
          isVisible={active}
        >
          <IntelligentWomanMap className="svg-icon" />
        </Animated>
        <IntelligentWallWindows className="icon-animation IntelligentWallWindows" style={{ opacity: active ? 1 : 0 }} />
        <div className="platform-title">
          <IntelligentPlatformTitle style={{ height: '100%' }} />
        </div>
      </div>
    );
  }
}

export default IntelligentPlatform;